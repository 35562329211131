<template>
  <div>
    <b-modal
      id="modal-reason-add"
      ref="modal"
      v-model="open"
      hide-footer
      no-close-on-backdrop
      size="lg"
      :title="modalTitle"
    >
      <b-overlay
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <ValidationObserver
          ref="createReasonForm"
          v-slot="{ handleSubmit }"
          slim
        >
          <b-form
            class="my-8"
            autocomplete="off"
            @submit.prevent="handleSubmit(handleFormSubmit)"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  label="Name"
                  label-cols-md="4"
                  label-for="h-cName"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="form.name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Name"
                      type="text"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  label="Type"
                  label-cols-md="4"
                  label-for="h-cName"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      v-model="form.keys"
                      :options="statusOptions"
                      :reduce="option => option.key"
                      label="name"
                      multiple
                      placeholder="Select Status"
                    >
                      <template v-slot:option="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Description"
                  label-cols-md="4"
                  label-for="h-cName"
                >
                  <b-form-input
                    id="Description"
                    v-model="form.description"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col
                offset-md="4"
              >
                <div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-1 custom-button-color"
                    type="submit"
                  >
                    Submit
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    @click="clearAll"
                  >
                    Clear
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </b-form>
        </ValidationObserver>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  VBModal,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ReasonRepository = RepositoryFactory.get('reasons')

function initialState() {
  return {
    open: false,
    id: null,
    modalPurpose: 'add',
    loading: false,
    form: {},
    statusOptions: [],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    modalTitle() {
      return this.modalPurpose === 'add' ? 'ADD NEW REASON' : 'UPDATE REASON'
    },
  },
  methods: {
    async openModal(id, purpose) {
      await Object.assign(this.$data, initialState())
      this.loading = true
      this.open = true
      this.modalPurpose = purpose
      this.id = id
      await this.readDataforStatus()
      if (this.modalPurpose === 'edit') {
        await this.fetchReasonDetails(this.id)
      }
      this.loading = false
    },
    async readDataforStatus() {
      try {
        const { data } = (await ReasonRepository.getStatusList()).data
        this.statusOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchReasonDetails(id) {
      try {
        const { data } = (await ReasonRepository.getReasonDetails(id)).data
        this.form = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async handleFormSubmit() {
      this.loading = true
      try {
        const payload = this.form
        let res
        if (this.modalPurpose === 'add') {
          res = await await ReasonRepository.createReason(payload)
        } else {
          res = await await ReasonRepository.updateReason(this.id, payload)
        }
        if (res.status === 200 || res.status === 201) {
          this.showSuccessMessage(`Reason ${this.modalPurpose === 'add' ? 'Added' : 'Updated'} Successfully`)
          this.open = false
          this.$parent.onClickRefresh()
        } else {
          this.showErrorMessage(`Cannot ${this.modalPurpose === 'add' ? 'Add' : 'Update'} Reason`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    clearAll() {
      this.form = {}
    },
  },
}
</script>
<style scoped>
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
